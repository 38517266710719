import * as React from 'react';
import {
  Heading,
  B2CComponentsProvider,
  Body,
  SSOPage,
} from '@edekadigital/b2c-components';
import {Footer, Header} from '../components';
import EdekaTitle from '../components/commons/Title';

const header = <Header />;
const footer = <Footer />;

export const Head = EdekaTitle;
const ServerError: React.FC = () => (
  <B2CComponentsProvider>
    <SSOPage header={header} footer={footer}>
      <Heading gutterBottom={true}>Ups, da ist was schief gegangen</Heading>
      <Body>
        Wir bitten um Entschuldigung. Es ist ein unerwarteter Fehler
        aufgetreten. Versuchen Sie es zu einem späteren Zeitpunkt erneut.
        <p>
          <a href={'/'}>Zurück zur Startseite</a>
        </p>
      </Body>
    </SSOPage>
  </B2CComponentsProvider>
);

export default ServerError;
